import { ChainNames } from 'config';
import type { PartialRecord } from 'types';

import coinPool from 'abis/smm/coinPool.json';
import tokenPool from 'abis/smm/tokenPool.json';
import Partner1 from 'assets/images/partners/partner1.svg';
import Partner1White from 'assets/images/partners/Partner1White.svg';
import Partner4 from 'assets/images/partners/partner4.svg';
import Partner4White from 'assets/images/partners/Partner4White.svg';
import Partner3 from 'assets/images/partners/partner3.svg';
import Partner3White from 'assets/images/partners/Partner3White.svg';
import Partner2 from 'assets/images/partners/partner2.svg';
import Partner2White from 'assets/images/partners/Partner2White.svg';
import Atlas from 'assets/images/poolSelection/atlas-brand.png';
import AtlasWhite from 'assets/images/poolSelection/Atlas-white.png';
import Ganesh from 'assets/images/poolSelection/ganesh-brand.png';
import GaneshWhite from 'assets/images/poolSelection/Ganesh-white.png';
import Vcred from 'assets/images/poolSelection/vcred-brand.png';

export type PoolAsset = {
	symbol: string;
	name: string;
	decimals: number;
	poolAbis: any;
	poolContractAddr: PartialRecord<ChainNames, string>;
	assetContractAddr: PartialRecord<ChainNames, string>;
	isNativeToken: boolean;
	icon: string;
	depositLimit: number;
};

type Info = {
	value: number;
	info: string;
};

type Vaults = {
	id: string | number;
	icon: string;
	alt: string;
	whiteIcon?: string;
};

type CurrentState = {
	title: string;
	value: string;
};

export type SMMPool = {
	id: string;
	name: string;
	disabled: boolean;
	illustration: { icon: string; whiteIcon: string };
	description: string;
	pnl: Info;
	risk: Info;
	reward: Info;
	volatility: Info;
	riskDesc: string;
	volatilityDesc: string;
	rewardDesc: string;
	backgroundColor: string;
	vaults: Vaults[];
	tags: {
		title: string;
		value: string;
	}[];
	route: { title: string; url: string };
	exchanges: { name: string; icon: string }[];
	targetAssetCategory: {
		name: string;
		chainType: string;
		coinIcon: string;
	};
	tokens: PoolAsset[];
	currentState: CurrentState[];
};

const sMMPools: SMMPool[] = [
	{
		id: 'atlas',
		name: 'Atlas',
		illustration: { icon: Atlas, whiteIcon: AtlasWhite },
		disabled: false,
		currentState: [
			{ title: 'TOTAL VOLUME', value: '$0,000,000' },
			{ title: 'TVL', value: '00.00%' },
			{ title: 'EST YIELD', value: '00.00%' },
			{ title: 'OVERALL PARTNER POINTS', value: '00.00%' },
		],
		backgroundColor:
			'linear-gradient(146deg, #FFF 54.04%, #F0FAFF 79.97%), linear-gradient(180deg, #F5C089 0%, #F4B372 100%)',
		description:
			'Maximise volume, points —>  delta neutral trade with high capital efficiency',
		pnl: { value: 50, info: '' },
		risk: {
			value: 50,
			info: 'Momentum strategies can break under sudden trend reversals. Forward testing is not yet completed for different scenarios.Due to incomplete risk analysis a smaller fraction of the pool is allocated as part of the reserve ratio',
		},
		reward: {
			value: 50,
			info: 'This agent generates higher rewards infrequently, and smoothes out over longer accounting periods',
		},
		volatility: {
			value: 50,
			info: 'Higher volatility and potentially illiquid markets benefit this agent. But it also provides rewards in periods of mid volatility as well',
		},
		riskDesc: 'Medium',
		volatilityDesc: 'Medium',
		rewardDesc: 'Medium',
		vaults: [
			{ id: 0, icon: Partner1, alt: '', whiteIcon: Partner1White },
			{ id: 1, icon: Partner4, alt: '', whiteIcon: Partner4White },
		],
		tags: [
			{ title: 'Estimated APY', value: '64.76%' },
			{ title: 'Total Volume', value: '$84M' },
		],
		route: { title: 'Launch vault', url: '' },
		exchanges: [
			{
				name: 'binance',
				icon: '/crypto/binance.svg',
			},
		],
		targetAssetCategory: {
			name: 'Avalanche',
			chainType: 'L1',
			coinIcon: '/crypto/avax.svg',
		},
		tokens: [
			{
				symbol: 'AVAX',
				name: 'Avalanche',
				decimals: 18,
				poolAbis: coinPool,
				poolContractAddr: {
					Avalanche: '0x3535Ee97915906A96c2178e6Ee809C19c79D927f',
					'Avalanche Fuji':
						'0x3535Ee97915906A96c2178e6Ee809C19c79D927f',
				},
				assetContractAddr: {
					Avalanche: '',
					'Avalanche Fuji': '',
				},
				isNativeToken: true,
				icon: '/crypto/avax.svg',
				depositLimit: 2.5,
			},
			{
				symbol: 'USDC',
				name: 'USDC',
				decimals: 6,
				poolAbis: tokenPool,
				poolContractAddr: {
					Avalanche: '0xa6E3065aeD97B485A51AbdCF946d9BBaadaB91a0',
					'Avalanche Fuji':
						'0xa6E3065aeD97B485A51AbdCF946d9BBaadaB91a0',
				},
				assetContractAddr: {
					Avalanche: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
					'Avalanche Fuji':
						'0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
				},
				isNativeToken: false,
				icon: '/crypto/usdc.svg',
				depositLimit: 25,
			},
			{
				symbol: 'BTC.B',
				name: 'Bitcoin',
				decimals: 8,
				poolAbis: tokenPool,
				poolContractAddr: {
					Avalanche: '0x2BcC758D075F7f6f5f4246bb060D896583FdF869',
					'Avalanche Fuji':
						'0x2BcC758D075F7f6f5f4246bb060D896583FdF869',
				},
				assetContractAddr: {
					Avalanche: '0x152b9d0FdC40C096757F570A51E494bd4b943E50',
					'Avalanche Fuji':
						'0x152b9d0FdC40C096757F570A51E494bd4b943E50',
				},
				isNativeToken: false,
				icon: '/crypto/btc.svg',
				depositLimit: 0.001,
			},
		],
	},
	{
		id: 'ganesh',
		name: 'Ganesh (WIP)',
		illustration: { icon: Ganesh, whiteIcon: GaneshWhite },
		disabled: false,
		currentState: [
			{ title: 'TOTAL VOLUME', value: '$0,000,000' },
			{ title: 'TVL', value: '00.00%' },
			{ title: 'EST YIELD', value: '00.00%' },
		],
		backgroundColor:
			'linear-gradient(146deg, #FFF 54.04%, #F0FAFF 79.97%), linear-gradient(180deg, #F5C089 0%, #F4B372 100%)',

		description: 'Use GenAI understand CT sentiment and degen trade',
		pnl: {
			value: 50,
			info: '',
		},
		risk: {
			value: 50,
			info: 'Momentum strategies can break under sudden trend reversals. Forward testing is not yet completed for different scenarios.Due to incomplete risk analysis a smaller fraction of the pool is allocated as part of the reserve ratio.',
		},
		reward: {
			value: 75,
			info: 'This agent generates higher rewards infrequently, and smoothes out over longer accounting periods',
		},
		volatility: {
			value: 75,
			info: 'Higher volatility and potentially illiquid markets benefit this agent. But it also provides rewards in periods of mid volatility as well',
		},
		riskDesc: 'Medium',
		volatilityDesc: 'Medium',
		rewardDesc: 'Medium',
		vaults: [{ id: 0, icon: Partner3, alt: '', whiteIcon: Partner3White }],
		route: { title: 'Launch vault', url: '' },
		tags: [
			{ title: 'Estimated APY', value: '10%' },
			{ title: 'Total TVL', value: '100K' },
		],
		exchanges: [
			{
				name: 'dydx',
				icon: '/crypto/dydx.svg',
			},
			{
				name: 'binance',
				icon: '/crypto/binance.svg',
			},
		],
		targetAssetCategory: {
			name: 'Avalanche',
			chainType: 'L1',
			coinIcon: '/crypto/avax.svg',
		},
		tokens: [
			{
				symbol: 'AVAX',
				name: 'Avalanche',
				decimals: 18,
				poolAbis: coinPool,
				poolContractAddr: {
					Avalanche: '0xE955628b659Ec24496f67561723771Fe072A0dcb',
					'Avalanche Fuji':
						'0xE955628b659Ec24496f67561723771Fe072A0dcb',
				},
				assetContractAddr: {
					Avalanche: '',
					'Avalanche Fuji': '',
				},
				isNativeToken: true,
				icon: '/crypto/avax.svg',
				depositLimit: 2.5,
			},
			{
				symbol: 'USDC',
				name: 'USDC',
				decimals: 6,
				poolAbis: tokenPool,
				poolContractAddr: {
					Avalanche: '0xb111975dD31a9d99C62b049DE170475e9F0D72e8',
					'Avalanche Fuji':
						'0xb111975dD31a9d99C62b049DE170475e9F0D72e8',
				},
				assetContractAddr: {
					Avalanche: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
					'Avalanche Fuji':
						'0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
				},
				isNativeToken: false,
				icon: '/crypto/usdc.svg',
				depositLimit: 25,
			},
			{
				symbol: 'BTC.B',
				name: 'Bitcoin',
				decimals: 8,
				poolAbis: tokenPool,
				poolContractAddr: {
					Avalanche: '0x26b32C13026905aC1B405B8e67AB370A99c38fBF',
					'Avalanche Fuji':
						'0x26b32C13026905aC1B405B8e67AB370A99c38fBF',
				},
				assetContractAddr: {
					Avalanche: '0x152b9d0FdC40C096757F570A51E494bd4b943E50',
					'Avalanche Fuji':
						'0x152b9d0FdC40C096757F570A51E494bd4b943E50',
				},
				isNativeToken: false,
				icon: '/crypto/btc.svg',
				depositLimit: 0.001,
			},
		],
	},
	{
		id: 'vcred',
		name: 'Vcred',
		illustration: { icon: Vcred, whiteIcon: Vcred },
		disabled: true,
		currentState: [
			{ title: 'TOTAL VOLUME', value: '$0,000,000' },
			{ title: 'TVL', value: '00.00%' },
			{ title: 'EST YIELD', value: '00.00%' },
		],
		backgroundColor:
			'linear-gradient(146deg, #FFF 54.04%, #F0FAFF 79.97%), linear-gradient(180deg, #F5C089 0%, #F4B372 100%)',

		description: 'Stake VCRED token to get access to your perp portfolio',
		pnl: {
			value: 50,
			info: '',
		},
		risk: {
			value: 50,
			info: 'Momentum strategies can break under sudden trend reversals. Forward testing is not yet completed for different scenarios.Due to incomplete risk analysis a smaller fraction of the pool is allocated as part of the reserve ratio.',
		},
		reward: {
			value: 75,
			info: 'This agent generates higher rewards infrequently, and smoothes out over longer accounting periods',
		},
		volatility: {
			value: 75,
			info: 'Higher volatility and potentially illiquid markets benefit this agent. But it also provides rewards in periods of mid volatility as well',
		},
		riskDesc: 'Medium',
		volatilityDesc: 'Medium',
		rewardDesc: 'Medium',
		vaults: [
			{ id: 0, icon: Partner2, alt: 'vcred', whiteIcon: Partner2White },
		],
		route: { title: 'Launch vault', url: '' },
		tags: [
			{ title: 'Estimated APY', value: '64.76%' },
			{ title: 'Total TVL', value: '' },
		],
		exchanges: [
			{
				name: 'dydx',
				icon: '/crypto/dydx.svg',
			},
			{
				name: 'binance',
				icon: '/crypto/binance.svg',
			},
		],
		targetAssetCategory: {
			name: 'Avalanche',
			chainType: 'L1',
			coinIcon: '/crypto/avax.svg',
		},
		tokens: [
			{
				symbol: 'AVAX',
				name: 'Avalanche',
				decimals: 18,
				poolAbis: coinPool,
				poolContractAddr: {
					Avalanche: '0xE955628b659Ec24496f67561723771Fe072A0dcb',
					'Avalanche Fuji':
						'0xE955628b659Ec24496f67561723771Fe072A0dcb',
				},
				assetContractAddr: {
					Avalanche: '',
					'Avalanche Fuji': '',
				},
				isNativeToken: true,
				icon: '/crypto/avax.svg',
				depositLimit: 2.5,
			},
			{
				symbol: 'USDC',
				name: 'USDC',
				decimals: 6,
				poolAbis: tokenPool,
				poolContractAddr: {
					Avalanche: '0xb111975dD31a9d99C62b049DE170475e9F0D72e8',
					'Avalanche Fuji':
						'0xb111975dD31a9d99C62b049DE170475e9F0D72e8',
				},
				assetContractAddr: {
					Avalanche: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
					'Avalanche Fuji':
						'0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
				},
				isNativeToken: false,
				icon: '/crypto/usdc.svg',
				depositLimit: 25,
			},
			{
				symbol: 'BTC.B',
				name: 'Bitcoin',
				decimals: 8,
				poolAbis: tokenPool,
				poolContractAddr: {
					Avalanche: '0x26b32C13026905aC1B405B8e67AB370A99c38fBF',
					'Avalanche Fuji':
						'0x26b32C13026905aC1B405B8e67AB370A99c38fBF',
				},
				assetContractAddr: {
					Avalanche: '0x152b9d0FdC40C096757F570A51E494bd4b943E50',
					'Avalanche Fuji':
						'0x152b9d0FdC40C096757F570A51E494bd4b943E50',
				},
				isNativeToken: false,
				icon: '/crypto/btc.svg',
				depositLimit: 0.001,
			},
		],
	},
	/*{
		id: 'delta-neutral-vault',
		name: 'Delta-neutral Vault',
		illustration: Candle,
		disabled: true,
		backgroundColor:
			'linear-gradient(146deg, #FFF 54.04%, #F0FAFF 79.97%), linear-gradient(180deg, #F5C089 0%, #F4B372 100%)',

		description:
			'Our AI model builds a delta neutral position to maximise swap fees and minimise IL by selecting the right bins on CLMMs',
		pnl: {
			value: 50,
			info: '',
		},
		risk: {
			value: 50,
			info: 'Momentum strategies can break under sudden trend reversals. Forward testing is not yet completed for different scenarios.Due to incomplete risk analysis a smaller fraction of the pool is allocated as part of the reserve ratio.',
		},
		reward: {
			value: 75,
			info: 'This agent generates higher rewards infrequently, and smoothes out over longer accounting periods',
		},
		volatility: {
			value: 75,
			info: 'Higher volatility and potentially illiquid markets benefit this agent. But it also provides rewards in periods of mid volatility as well',
		},
		riskDesc: 'Medium',
		volatilityDesc: 'Medium',
		rewardDesc: 'Medium',
		/!*tags: [
			{
				name: 'Avalanche ',
				color: '#FF7D00',
			},
		],*!/
		exchanges: [
			{
				name: 'dydx',
				icon: '/crypto/dydx.svg',
			},
			{
				name: 'binance',
				icon: '/crypto/binance.svg',
			},
		],
		targetAssetCategory: {
			name: 'Avalanche',
			chainType: 'L1',
			coinIcon: '/crypto/avax.svg',
		},
		tokens: [
			{
				symbol: 'AVAX',
				name: 'Avalanche',
				decimals: 18,
				poolAbis: coinPool,
				poolContractAddr: {
					Avalanche: '0xE955628b659Ec24496f67561723771Fe072A0dcb',
					'Avalanche Fuji':
						'0xE955628b659Ec24496f67561723771Fe072A0dcb',
				},
				assetContractAddr: {
					Avalanche: '',
					'Avalanche Fuji': '',
				},
				isNativeToken: true,
				icon: '/crypto/avax.svg',
				depositLimit: 2.5,
			},
			{
				symbol: 'USDC',
				name: 'USDC',
				decimals: 6,
				poolAbis: tokenPool,
				poolContractAddr: {
					Avalanche: '0xb111975dD31a9d99C62b049DE170475e9F0D72e8',
					'Avalanche Fuji':
						'0xb111975dD31a9d99C62b049DE170475e9F0D72e8',
				},
				assetContractAddr: {
					Avalanche: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
					'Avalanche Fuji':
						'0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
				},
				isNativeToken: false,
				icon: '/crypto/usdc.svg',
				depositLimit: 25,
			},
			{
				symbol: 'BTC.B',
				name: 'Bitcoin',
				decimals: 8,
				poolAbis: tokenPool,
				poolContractAddr: {
					Avalanche: '0x26b32C13026905aC1B405B8e67AB370A99c38fBF',
					'Avalanche Fuji':
						'0x26b32C13026905aC1B405B8e67AB370A99c38fBF',
				},
				assetContractAddr: {
					Avalanche: '0x152b9d0FdC40C096757F570A51E494bd4b943E50',
					'Avalanche Fuji':
						'0x152b9d0FdC40C096757F570A51E494bd4b943E50',
				},
				isNativeToken: false,
				icon: '/crypto/btc.svg',
				depositLimit: 0.001,
			},
		],
	},
	{
		id: 'orderbook-vault',
		name: 'Orderbook Vault',
		illustration: AiEngine,
		disabled: true,
		backgroundColor:
			'linear-gradient(146deg, #FFF 54.04%, #F0FAFF 79.97%), linear-gradient(180deg, #F5C089 0%, #F4B372 100%)',
		description:
			'Our AI model maximises rewards by quoting orders with optimal spreads maximising returns, subject to greater risk. Lending vaults provide a lower risk at lower interest rate',
		pnl: { value: 50, info: '' },
		risk: {
			value: 50,
			info: 'Momentum strategies can break under sudden trend reversals. Forward testing is not yet completed for different scenarios.Due to incomplete risk analysis a smaller fraction of the pool is allocated as part of the reserve ratio',
		},
		reward: {
			value: 50,
			info: 'This agent generates higher rewards infrequently, and smoothes out over longer accounting periods',
		},
		volatility: {
			value: 50,
			info: 'Higher volatility and potentially illiquid markets benefit this agent. But it also provides rewards in periods of mid volatility as well',
		},
		riskDesc: 'Medium',
		volatilityDesc: 'Medium',
		rewardDesc: 'Medium',
		/!*tags: [
			{
				name: 'Avalanche',
				color: '#1CCAD5',
			},
		],*!/
		exchanges: [
			{
				name: 'binance',
				icon: '/crypto/binance.svg',
			},
		],
		targetAssetCategory: {
			name: 'Avalanche',
			chainType: 'L1',
			coinIcon: '/crypto/avax.svg',
		},
		tokens: [
			{
				symbol: 'AVAX',
				name: 'Avalanche',
				decimals: 18,
				poolAbis: coinPool,
				poolContractAddr: {
					Avalanche: '0x3535Ee97915906A96c2178e6Ee809C19c79D927f',
					'Avalanche Fuji':
						'0x3535Ee97915906A96c2178e6Ee809C19c79D927f',
				},
				assetContractAddr: {
					Avalanche: '',
					'Avalanche Fuji': '',
				},
				isNativeToken: true,
				icon: '/crypto/avax.svg',
				depositLimit: 2.5,
			},
			{
				symbol: 'USDC',
				name: 'USDC',
				decimals: 6,
				poolAbis: tokenPool,
				poolContractAddr: {
					Avalanche: '0xa6E3065aeD97B485A51AbdCF946d9BBaadaB91a0',
					'Avalanche Fuji':
						'0xa6E3065aeD97B485A51AbdCF946d9BBaadaB91a0',
				},
				assetContractAddr: {
					Avalanche: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
					'Avalanche Fuji':
						'0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
				},
				isNativeToken: false,
				icon: '/crypto/usdc.svg',
				depositLimit: 25,
			},
			{
				symbol: 'BTC.B',
				name: 'Bitcoin',
				decimals: 8,
				poolAbis: tokenPool,
				poolContractAddr: {
					Avalanche: '0x2BcC758D075F7f6f5f4246bb060D896583FdF869',
					'Avalanche Fuji':
						'0x2BcC758D075F7f6f5f4246bb060D896583FdF869',
				},
				assetContractAddr: {
					Avalanche: '0x152b9d0FdC40C096757F570A51E494bd4b943E50',
					'Avalanche Fuji':
						'0x152b9d0FdC40C096757F570A51E494bd4b943E50',
				},
				isNativeToken: false,
				icon: '/crypto/btc.svg',
				depositLimit: 0.001,
			},
		],
	},*/
];

export { sMMPools };
