import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import type { PoolAsset, SMMPool } from 'data';
import { sMMPools } from 'data';

export type ActivePoolState = {
	pool: SMMPool | undefined;
	token: PoolAsset | undefined;
};

const initialState: ActivePoolState = {
	pool: undefined,
	token: undefined,
};

export const activePoolSlice = createSlice({
	name: 'active-pool',
	initialState,
	reducers: {
		setActivePool: (
			state: Draft<ActivePoolState>,
			action: PayloadAction<SMMPool['id']>,
		) => {
			// set active strategy based on id or select a fallback
			const newPool =
				sMMPools.find((pool) => pool.id === action.payload) ||
				sMMPools[0];
			const oldTokenSymbol = state.token?.symbol;
			const activeToken = oldTokenSymbol
				? newPool.tokens.find((t) => t.symbol === oldTokenSymbol)
				: newPool.tokens[0];

			state.pool = newPool;
			state.token = activeToken;
		},
		switchActiveToken: (
			state: Draft<ActivePoolState>,
			action: PayloadAction<{
				symbol: string;
			}>,
		) => {
			const newToken = state.pool?.tokens.find(
				(t) => t.symbol === action.payload.symbol,
			);

			if (newToken) {
				state.token = newToken;
			}
		},
	},
});

export const activePoolActions = activePoolSlice.actions;
const activePoolReducer = activePoolSlice.reducer;

export default activePoolReducer;
