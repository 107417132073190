import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Checkbox, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { FlexCol, FlexRow } from 'components/Flex';
import { DisclaimerData } from 'data/disclaimer';
import { Gutter } from 'components/Gutter';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Text = styled(Typography)`
	:hover {
		cursor: pointer;
	}
`;

const DisclaimerWrapper = styled(FlexCol)`
	max-height: 200px;
	width: 100%;
	overflow: scroll;
	flex-wrap: nowrap;
	padding: 10px;
	border-radius: 10px;
	border: 1px solid #235ee1;
`;

const StyledButton = styled(Button)`
	width: 100%;
	padding: 14px;
`;

const StyledWrapper = styled(FlexCol)`
	width: 100%;
`;

type DisclaimerProps = {
	checked: boolean;
	setChecked: Dispatch<SetStateAction<boolean>>;
};

export const Disclaimer: React.ComponentType<DisclaimerProps> = ({
	checked,
	setChecked,
}) => {
	const [show, setShow] = useState(false);

	return (
		<StyledWrapper>
			<FlexRow align={'center'}>
				<Checkbox
					checked={checked}
					onChange={(e) => setChecked(e.target.checked)}
				/>
				<Text variant={'subtitle2'} onClick={() => setShow(!show)}>
					I accept the Terms and Conditions
				</Text>
				{show ? (
					<ArrowDropUpIcon style={{ color: '#fff' }} />
				) : (
					<ArrowDropDownIcon style={{ color: '#fff' }} />
				)}
			</FlexRow>
			{show && (
				<>
					<DisclaimerWrapper>
						{DisclaimerData.map((d) => (
							<FlexCol key={d}>
								<Typography
									variant={'subtitle2'}
									style={{
										fontFamily: 'DM Mono, sans-serif',
									}}
								>
									* {d}
								</Typography>
								<Gutter spacing={2} />
							</FlexCol>
						))}
					</DisclaimerWrapper>
					<Gutter />
				</>
			)}
			<StyledButton
				variant={'contained'}
				onClick={() => {
					window.open('https://discord.gg/vcred');
				}}
			>
				Join Discord
			</StyledButton>
		</StyledWrapper>
	);
};
