const sMM7dData = [
	{
		date: '04/04/2023',
		value: 0,
	},
	{
		date: '05/04/2023',
		value: 1.7,
	},
	{
		date: '06/04/2023',
		value: 2.7,
	},
	{
		date: '07/04/2023',
		value: 1.7,
	},
	{
		date: '08/04/2023',
		value: 1.3,
	},
	{
		date: '09/04/2023',
		value: 1.5,
	},
	{
		date: '10/04/2023',
		value: 2.6,
	},
];

const sMM30dData = [
	{
		date: '04/04/2023',
		value: 0,
	},
	{
		date: '05/04/2023',
		value: 1.7,
	},
	{
		date: '06/04/2023',
		value: 2.7,
	},
	{
		date: '07/04/2023',
		value: 1.7,
	},
	{
		date: '08/04/2023',
		value: 1.3,
	},
	{
		date: '09/04/2023',
		value: 1.5,
	},
	{
		date: '10/04/2023',
		value: 2.6,
	},
	{
		date: '11/04/2023',
		value: 3.7,
	},
	{
		date: '12/04/2023',
		value: 5.5,
	},
	{
		date: '13/04/2023',
		value: 6.7,
	},
	{
		date: '14/04/2023',
		value: 6.4,
	},
	{
		date: '15/04/2023',
		value: 9.3,
	},
	{
		date: '16/04/2023',
		value: 10.3,
	},
	{
		date: '17/04/2023',
		value: 12.2,
	},
	{
		date: '18/04/2023',
		value: 7.1,
	},
];

export { sMM7dData, sMM30dData };
