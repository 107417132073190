export const DisclaimerData = [
	'You represent and warrant that you are not a person and/or not representing a legal entity which resides in, is a citizen of, is located or incorporated in, has registered office in or is otherwise subject to the jurisdiction of the United States of America or any Prohibited Localities, and that you are not a Restricted Person (each as defined in these Terms). There are no exceptions, and you represent and warrant that you are not using a VPN and/or are not otherwise circumventing this prohibition.',
	'You acknowledge that the Site Operator is not authorized or regulated by any financial market regulatory authority, including the United Kingdom Financial Conduct Authority (FCA). Nothing on this website or on Kamino Finance is intended to constitute marketing or promotion, and the content of this website and Kamino Finance is not intended for UK consumers. Consumer protections under UK regulations do not apply, and else you agree to waive any protection afforded under FCA regulations. The Site Operator does not provide services to the UK.',
	'You must be permitted to use the Site and the Kamino Finance Smart Contract System under applicable laws, and you must always comply with all applicable laws. Such applicable laws may vary depending on where you are domiciled, located, incorporated, operate out of, etc., and only you are responsible for ensuring full compliance.',
	'You acknowledge and agree that the Site is provided for informational purposes only and neither the Site Operator nor any individual or group of the Kamino Finance project team members, contributors, or similar are in control of Solana or the Kamino Finance Smart Contract System, have custody over your funds, the ability or duty to transact on your behalf or the power to reverse your transactions.',
	"You acknowledge and agree that the Site does not constitute any financial advice and is only being provided 'as is' and 'as available' without any representation, warranties or guarantees whatsoever.",
	'You acknowledge and agree that there is no legal or factual relationship between you and the Site Operator and/or any individual or group of the Kamino Finance project team members, contributors, or similar relating to Kamino Finance. The Kamino Finance Smart Contract System may be accessed through a variety of means and access requires third party software (e.g., wallets) for which you are fully responsible.',
	"You acknowledge and agree that blockchain and DeFi, including the Kamino Finance Smart Contract System, are of novel and experimental nature and are associated with a variety of risks, which you have familiarized yourself with and accepted. The Site and the Kamino Finance Smart Contract System are available 'as is' and 'as available' without any representation, warranties or guarantees whatsoever and the Site Operator is not, to the maximum extent permitted under applicable law, liable for any damages incurred by you in connection with the use of the Site or the Kamino Finance Smart Contract System.",
	'You, to the maximum extent permitted under applicable law, release all present and future claims against the Site Operator, and against any individual or group of Kamino Finance project team members, contributors or similar related to the use of the Kamino Finance Smart Contract System.\n',
	'By entering into these Terms, you waive your potential right to participate in class actions and agree that any disputes are resolved pursuant to binding, confidential arbitration, whereby your potential right to a jury trial is waived.',
];
