import { take, fork, call, put } from 'redux-saga/effects';
import {
	Deposits,
	poolDepositsActions,
	RequestDepositPayload,
} from 'store/pool-deposits/index';
import { PayloadAction } from '@reduxjs/toolkit';

import { sMMPools } from 'data';
import { getContract } from 'utils/contractHelpers';
import { ethers } from 'ethers';
import { roundTo } from 'utils/roundTo';
import { depositActions } from 'store/deposit';
import { withdrawalActions } from 'store/withdrawal';

const getAssetDeposits = async (
	params: RequestDepositPayload,
): Promise<Deposits> => {
	const activePool =
		sMMPools.find((pool) => pool.id === params.activePoolId) || sMMPools[0];
	const poolTokens = activePool.tokens;

	return Promise.all(
		poolTokens.map(
			(token) =>
				// eslint-disable-next-line no-async-promise-executor
				new Promise<Deposits[0]>(async (resolve, reject) => {
					const abis = token.poolAbis;
					const contractAddr = token.poolContractAddr.Avalanche;
					const library = params.library;

					if (!abis || !contractAddr || !library) {
						return reject(false);
					}

					const depositContract = getContract(
						abis,
						contractAddr,
						library.getSigner(),
					);

					const balances = await depositContract.balances(
						params.account,
					);

					const shares = balances.share;
					const amountInTokens =
						await depositContract.getDepositTokensForShares(shares);
					const totalDeposit = ethers.utils.formatUnits(
						amountInTokens,
						token.decimals,
					);

					resolve({
						deposit: totalDeposit,
						token: token.symbol,
					});
				}),
		),
	);
};

function* fetchPoolDeposits(params: RequestDepositPayload) {
	try {
		const deposits: Deposits = yield call(getAssetDeposits, params);
		yield put(poolDepositsActions.requestDepositsSuccess(deposits));
	} catch (error) {
		yield put(poolDepositsActions.requestDepositsFailed());
	}
}

export default function* saga() {
	while (true) {
		const action: PayloadAction<RequestDepositPayload> = yield take([
			poolDepositsActions.requestDeposits.type,
			depositActions.depositSuccess.type,
			withdrawalActions.withdrawalSuccess.type,
		]);
		yield fork(fetchPoolDeposits, action.payload);
	}
}
