import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, OutlinedInput, Typography } from '@mui/material';
import { roundTo } from 'utils/roundTo';
import { ethers } from 'ethers';
import { AssetDropdownSelect } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useWeb3React } from '@web3-react/core';
import { withdrawalActions } from 'store/withdrawal';
import { walletModalActions } from 'store/wallet-modal';
import { toast } from 'react-toastify';

const inputStyles = {
	'&, &:hover': {
		outline: '1px solid #235EE1',
	},
	color: 'white',
	fontWeight: 500,
	'& .MuiOutlinedInput-root': {
		fontWeight: 500,
	},
	'& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
		display: 'none',
	},
};

const WithdrawPanel: React.ComponentType = () => {
	const dispatch = useAppDispatch();
	const { account, library } = useWeb3React();
	const deposits = useAppSelector((state) => state.poolDeposits.deposits);
	const withdrawing = useAppSelector(
		(state) => state.withdrawals.withdrawing,
	);
	const [withdrawalAmount, setWithdrawalAmount] = useState('');

	const selectedAsset = useAppSelector((state) => state.activePool.token);

	const walletBalances = useAppSelector(
		(state) => state.walletBalances.balances,
	);

	const assetBalance = useMemo(() => {
		if (!walletBalances || !selectedAsset) {
			return 0;
		}

		const balance = walletBalances.find(
			(b) => b.token === selectedAsset.symbol,
		);

		if (!balance) {
			return 0;
		}

		return balance.balance;
	}, [selectedAsset, walletBalances]);

	const fillMaxAmount = useCallback(() => {
		if (!selectedAsset) {
			return;
		}

		let amount = '0';
		const asset = deposits.find((d) => d.token === selectedAsset.symbol);

		if (asset) {
			amount = asset.deposit;
		}

		setWithdrawalAmount(amount);
	}, [deposits, selectedAsset]);

	const endAdornment = useMemo(() => {
		return (
			<Box>
				<Button onClick={fillMaxAmount}>
					<Typography fontSize={12} fontWeight={500}>
						MAX
					</Typography>
				</Button>
			</Box>
		);
	}, [fillMaxAmount]);

	useEffect(() => {
		setWithdrawalAmount('');
	}, [selectedAsset]);

	const triggerWithdrawal = useCallback(() => {
		if (!selectedAsset) {
			return;
		}

		if (!account) {
			dispatch(walletModalActions.openWalletModal());
			return;
		}

		dispatch(
			withdrawalActions.withdraw({
				amount: withdrawalAmount,
				library,
				account,
				asset: selectedAsset,
			}),
		);
	}, [account, dispatch, library, selectedAsset, withdrawalAmount]);

	const isFormValid = useMemo(() => {
		let cleanedAmount = '0';
		cleanedAmount = Number(parseFloat(withdrawalAmount)).toString();

		if (!cleanedAmount || cleanedAmount === 'NaN' || cleanedAmount === '0')
			return false;

		const tentativeWithdrawalValue = ethers.utils.parseUnits(
			cleanedAmount || '0',
			selectedAsset?.decimals || 18,
		);

		const currentDeposit = deposits.find(
			(d) => d.token === selectedAsset?.symbol,
		);

		if (!currentDeposit) return false;

		const parsedTotalDeposit = ethers.utils.parseUnits(
			currentDeposit.deposit,
			selectedAsset?.decimals || 18,
		);

		return tentativeWithdrawalValue.lte(parsedTotalDeposit);
	}, [withdrawalAmount, selectedAsset, deposits]);

	const isWithdrawalBtnActive = useMemo(() => {
		return account ? !isFormValid || withdrawing : false;
	}, [account, isFormValid, withdrawing]);

	const buttonLabel = useMemo(() => {
		if (!account) return 'Connect Wallet';

		if (withdrawing) return 'Withdrawing...';

		return 'Withdraw';
	}, [account, withdrawing]);

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
			>
				<Typography color="#E6E6E9">Select asset</Typography>
				<Box display="flex" alignItems="center">
					<Typography color="#E6E6E9" fontWeight={500} fontSize={12}>
						{roundTo(
							parseFloat(
								ethers.utils.formatUnits(
									assetBalance,
									selectedAsset?.decimals || 18,
								),
							),
							4,
						)}{' '}
						{selectedAsset?.symbol}
					</Typography>
					<Box width={4} />
					<img src="/icons/wallet.png" />
				</Box>
			</Box>
			<Box height={8} />
			<AssetDropdownSelect />

			<Box height={16} />

			<Typography color="#E6E6E9">Amount</Typography>
			<Box height={8} />

			<OutlinedInput
				disabled={withdrawing}
				placeholder="0.00"
				value={withdrawalAmount}
				onChange={(event) => {
					setWithdrawalAmount(event.target.value);
				}}
				type="number"
				fullWidth
				margin="dense"
				sx={inputStyles}
				endAdornment={endAdornment}
			/>

			<Box height={28} />

			<Button
				onClick={() => {
					toast(
						'Whitelisted access only: Contact info@vcred.trade if interested',
						{
							type: 'info',
						},
					);
				}}
				// onClick={triggerWithdrawal}
				// disabled={isWithdrawalBtnActive}
				sx={{
					height: 52,
				}}
				variant="contained"
				fullWidth
				color="primary"
			>
				<Typography
					variant={'button'}
					fontWeight="bold"
					py="6px"
					style={{ lineHeight: '120%' }}
				>
					{buttonLabel}
				</Typography>
			</Button>
		</Box>
	);
};

export { WithdrawPanel };
