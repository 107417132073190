import React, { useMemo, useState } from 'react';
import {
	Area,
	AreaChart,
	CartesianGrid,
	Label as ChartLabel,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import styled from '@emotion/styled';
import { sMM30dData, sMM7dData } from 'data';
import {
	activeDotStyle,
	commonChartMargins,
	tooltipStyle,
	xAxisLabelStyle,
	xAxisTickStyle,
	yAxisTickStyle,
} from 'pages/dashboard/components/commons';
import moment from 'moment';
import { Box, Typography } from '@mui/material';

const Card = styled.div`
	position: relative;
	padding: 30px 0;

	@media screen and (max-width: 600px) {
		padding: 20px 5px 0 0;
	}
`;

const StyledResponsiveContainer = styled(ResponsiveContainer)`
	min-height: 500px;
	width: 100%;
`;

const colorBreakPoint = 0;

const TabGroup = styled(Box)`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	grid-gap: 4px;
	padding: 4px;
	background-color: transparent;
	border-radius: 4px;
`;

const TabButton = styled(Box)<{ selected?: boolean }>`
	flex: 1;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(props) =>
		props.selected ? '#235EE1' : 'transparent'};
	border-radius: 4px;
`;

const TabText = styled(Typography)`
	font-size: 18px;
	font-weight: 500;
	margin: 6px 8px;
`;

const StyledDivider = styled('div')`
	width: 100%;
	border-bottom: 1px solid #f6fcff;
`;

type ChartTimePeriod = '7d' | '30d' | '90d' | '1y' | 'All';

const PoolPnLChart: React.ComponentType = () => {
	const [timePeriod, setTimePeriod] = useState<ChartTimePeriod>('30d');
	const isDark = true;

	const currentDataSet = useMemo(() => {
		const dataset = timePeriod === '7d' ? sMM7dData : sMM30dData;

		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return dataset.map((pnl) => {
			return {
				date: moment(pnl.date, 'DD/MM/YYYY').valueOf(),
				value: pnl.value,
			};
		});
	}, [timePeriod]);

	const { min, max } = useMemo(() => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return currentDataSet.reduce(
			(result, dataPoint) => ({
				min:
					dataPoint.value < result.min || result.min === 0
						? dataPoint.value
						: result.min,
				max:
					dataPoint.value > result.max || result.max === 0
						? dataPoint.value
						: result.max,
			}),
			{ min: 0, max: 0 },
		);
	}, [currentDataSet]);

	const colorBreakPointPercentage = `${
		(1 - (colorBreakPoint - min) / (max - min)) * 100
	}%`;

	const chart = useMemo(() => {
		return (
			<AreaChart data={currentDataSet} margin={commonChartMargins}>
				<defs>
					<linearGradient
						id="pnlStrokeGradient"
						x1="0%"
						y1="0%"
						x2="0%"
						y2="100%"
					>
						<stop offset="0%" stopColor="green" />
						<stop
							offset={colorBreakPointPercentage}
							stopColor="#235EE1"
						/>
						<stop
							offset={colorBreakPointPercentage}
							stopColor="#235EE1"
						/>
						<stop offset="100%" stopColor="red" />
					</linearGradient>
					<linearGradient
						id="pnlFillGradient"
						x1="0%"
						y1="0%"
						x2="0%"
						y2="100%"
					>
						<stop offset="0%" stopColor="rgba(35, 94, 225, 0.60)" />
						<stop
							offset="57.49%"
							stopColor="rgba(35, 94, 225, 0.60)"
						/>
						<stop
							offset="121.3%"
							stopColor="rgba(35, 94, 225, 0.00)"
						/>
					</linearGradient>
				</defs>

				<CartesianGrid strokeDasharray="5 5" stroke="#8392BC" />

				<XAxis
					domain={[
						currentDataSet[0].date,
						currentDataSet[currentDataSet.length - 1].date,
					]}
					type={'number'}
					dataKey="date"
					tick={xAxisTickStyle(isDark)}
					tickCount={6}
					tickFormatter={(value) =>
						moment(value).format('DD/MM/YYYY')
					}
				>
					<ChartLabel
						value="Time →"
						offset={6}
						position="bottom"
						style={xAxisLabelStyle(isDark)}
					/>
				</XAxis>

				<YAxis
					width={20}
					tick={yAxisTickStyle(isDark)}
					tickFormatter={(value: number) => `${value}%`}
				/>

				<Tooltip
					labelFormatter={(value) =>
						moment(value).format('DD/MM/YYYY')
					}
					formatter={(value) => [`${value}%`, 'PnL']}
					contentStyle={tooltipStyle(isDark)}
				/>
				<Area
					type="linear"
					dataKey="value"
					strokeWidth={3}
					fill="url(#pnlFillGradient)"
					stroke="var(--Brand-Primary-Primary-Blue, #235EE1)"
					dot={false}
					activeDot={activeDotStyle(isDark)}
				/>
			</AreaChart>
		);
	}, [colorBreakPointPercentage, currentDataSet, isDark]);

	return (
		<Box style={{ padding: 30 }}>
			<Typography variant={'h5'}>Protocol Volume</Typography>
			<Box height={16} />
			<StyledDivider />
			<Box height={16} />
			<Box display="flex" justifyContent="flex-start">
				<TabGroup>
					<TabButton
						selected={timePeriod === '7d'}
						onClick={() => setTimePeriod('7d')}
					>
						<TabText>7d</TabText>
					</TabButton>

					<TabButton
						selected={timePeriod === '30d'}
						onClick={() => setTimePeriod('30d')}
					>
						<TabText>30d</TabText>
					</TabButton>
					<TabButton
						selected={timePeriod === '90d'}
						onClick={() => setTimePeriod('90d')}
					>
						<TabText>90d</TabText>
					</TabButton>
					<TabButton
						selected={timePeriod === '1y'}
						onClick={() => setTimePeriod('1y')}
					>
						<TabText>1Y</TabText>
					</TabButton>
					<TabButton
						selected={timePeriod === 'All'}
						onClick={() => setTimePeriod('All')}
					>
						<TabText>All</TabText>
					</TabButton>
				</TabGroup>
			</Box>
			<Card>
				<StyledResponsiveContainer>{chart}</StyledResponsiveContainer>
			</Card>
		</Box>
	);
};

export { PoolPnLChart };
