import { PayloadAction } from '@reduxjs/toolkit';
import { call, fork, put, take, select } from 'redux-saga/effects';
import { depositActions } from 'store/deposit/index';
import type { InitiateDepositParams } from 'store/deposit/index';
import { getContract, getErc20Contract } from 'utils/contractHelpers';
import { ethers } from 'ethers';
import { RootState } from 'store/index';
import { SMMPool } from 'data';
import { toast } from 'react-toastify';

const isDepositWithinAllowance = async (
	params: InitiateDepositParams,
): Promise<boolean> => {
	const { asset, library, account, amount } = params;

	if (!asset.assetContractAddr.Avalanche) {
		return false;
	}

	const assetContract = getErc20Contract(
		asset.assetContractAddr.Avalanche,
		library.getSigner(),
	);

	const allowance = await assetContract.allowance(
		account,
		asset.poolContractAddr.Avalanche,
	);

	const requestedDepositAmount = ethers.utils.parseUnits(
		amount,
		asset.decimals,
	);

	return requestedDepositAmount.lte(allowance);
};

const requestLimitIncrease = async (params: InitiateDepositParams) => {
	const { library, asset } = params;

	if (!asset.assetContractAddr.Avalanche) {
		return;
	}

	const assetContract = getErc20Contract(
		asset.assetContractAddr.Avalanche,
		library.getSigner(),
	);

	const tx = await assetContract.approve(
		asset.poolContractAddr.Avalanche,
		ethers.constants.MaxUint256,
	);

	await tx.wait();
};

const deposit = async (params: InitiateDepositParams) => {
	const { asset, amount, library } = params;

	if (!asset.poolContractAddr.Avalanche) {
		return;
	}

	const depositContract = getContract(
		asset.poolAbis,
		asset.poolContractAddr.Avalanche,
		library.getSigner(),
	);

	const parsedAmount = ethers.utils.parseUnits(amount, asset.decimals);

	let tx;
	if (asset.isNativeToken) {
		tx = await depositContract.deposit({
			value: parsedAmount,
		});
	} else {
		tx = await depositContract.deposit(parsedAmount);
	}

	const receipt = await tx.wait();
	console.log(receipt);
};

function* initiateDeposit(params: InitiateDepositParams) {
	const { asset, library, account } = params;

	try {
		const activePool: SMMPool = yield select(
			(state: RootState) => state.activePool.pool,
		);

		if (!asset.isNativeToken) {
			const isWithinLimit: boolean = yield call(
				isDepositWithinAllowance,
				params,
			);

			if (!isWithinLimit) {
				yield call(requestLimitIncrease, params);
			}

			yield call(deposit, params);
		} else {
			yield call(deposit, params);
		}

		toast('Deposit successful', {
			type: 'success',
		});
		yield put(
			depositActions.depositSuccess({
				library,
				account,
				activePoolId: activePool.id,
			}),
		);
	} catch (error) {
		toast('Deposit failed', {
			type: 'error',
		});

		yield put(depositActions.depositFailed());
	}
}

export default function* saga() {
	while (true) {
		const action: PayloadAction<InitiateDepositParams> = yield take(
			depositActions.deposit.type,
		);

		yield fork(initiateDeposit, action.payload);

		yield take([
			depositActions.depositSuccess.type,
			depositActions.depositFailed.type,
		]);
	}
}
