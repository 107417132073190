import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import assetPricesReducer from 'store/asset-prices';
import walletModalReducer from 'store/wallet-modal';
import activePoolReducer from 'store/active-pool';
import poolDepositReducer from 'store/pool-deposits';
import walletBalancesReducer from 'store/wallet-balances';
import depositReducer from 'store/deposit';
import withdrawalReducer from 'store/withdrawal';

import IndexSaga from './Index-Saga';

const rootReducer = combineReducers({
	assetPrices: assetPricesReducer,
	walletModal: walletModalReducer,
	activePool: activePoolReducer,
	poolDeposits: poolDepositReducer,
	walletBalances: walletBalancesReducer,
	deposits: depositReducer,
	withdrawals: withdrawalReducer,
});

const sagaMiddleware = createSagaMiddleware();

// attach saga middleware to redux store
const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(sagaMiddleware),
});

sagaMiddleware.run(IndexSaga);

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export { store };
export type { RootState, AppDispatch };
