import React from 'react';
import { Box } from '@mui/material';

import { css } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { ConnectionButton } from 'components/Navbar/ConnectionButton';
import { WalletDialog } from 'components/WalletDialog';
import { FlexRow } from 'components/Flex';
import { DepositButton } from 'pages/dashboard/components/DepositeButton';

const navContainer = css`
	background-color: #1a1a33;
	height: 65px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
	border-bottom: 1px solid #33334a;
`;

const NavContainer = styled(Box)`
	${navContainer}
`;

const Navbar: React.ComponentType = () => {
	return (
		<>
			<NavContainer
				sx={{ display: 'flex', justifyContent: 'space-between' }}
			>
				<img src="/brand-logo.svg" height={36} />

				<FlexRow>
					<DepositButton />
					<Box width={10} />
					<ConnectionButton />
				</FlexRow>
			</NavContainer>

			<WalletDialog />
		</>
	);
};

export { Navbar };
